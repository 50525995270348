import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, getContext, put, call} from 'redux-saga/effects'
import {Dictionary, keyBy} from 'lodash'
import Patient from '../../models/Patient'
import Api, {UnauthorizedError} from 'Api'
import {actions as searchActions} from './search'

export type PatientsState = {
  patients: Dictionary<Patient>
  loading: boolean
  error: string | undefined
}
export const initialState: PatientsState = {
  patients: {},
  loading: false,
  error: undefined,
}

export const actions = {
  set: createAction<{patients: Patient[]}>('patients/set'),
  get: createAction<void>('patients/get'),
  add: createAction<{patient: Patient}>('patients/add'),
  update: createAction<{patient: Patient}>('patients/update'),
  delete: createAction<{id: string}>('patients/delete'),
  setLoading: createAction<{loading: boolean}>('patients/setLoading'),
  setError: createAction<{error?: string}>('patients/setError'),
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.set, (state, action: ReturnType<typeof actions.set>) => {
      const {patients} = action.payload;
      state.patients = keyBy(patients, 'id');
    })
    .addCase(actions.setLoading, (state, action: ReturnType<typeof actions.setLoading>) => {
      const {loading} = action.payload;
      state.loading = loading;
    })
    .addCase(actions.setError, (state, action: ReturnType<typeof actions.setError>) => {
      const {error} = action.payload;
      state.error = error;
    });
});

const getSaga = function*() {
  const api: Api = yield getContext('api');
  const response = yield call(api.getPatients);
  if ('code' in response) {
    if (response['code'] === 503) {
      yield put(actions.setError({error: response['message']}))
  }
  } else {
    const patients: Patient[] = response;
    yield put(actions.set({patients}));
  }
};

const addSaga = function*(action: ReturnType<typeof actions.add>) {
  const {patient} = action.payload
  const api: Api = yield getContext('api')
  yield put(actions.setLoading({loading: true}))
  yield put(actions.setError({error: undefined}))
  try {
    yield call(api.addPatient, patient)
    yield put(searchActions.refreshSearch())
  } catch (e) {
    const error = e as any
    if (error === UnauthorizedError) {
      // throw so it redirects the user to the login page
      throw error
    }
    yield put(actions.setError({error: error.message}))
  }
  yield put(actions.setLoading({loading: false}))
}

const deleteSaga = function*(action: ReturnType<typeof actions.delete>) {
  const {id} = action.payload
  const api: Api = yield getContext('api')
  yield call(api.deletePatient, id)
  yield put(searchActions.refreshSearch())
}

const updateSaga = function*(action: ReturnType<typeof actions.update>) {
  const {patient} = action.payload
  const api: Api = yield getContext('api')
  yield put(actions.setLoading({loading: true}))
  yield put(actions.setError({error: undefined}))
  try {
    yield call(api.updatePatient, patient)
    yield put(searchActions.refreshSearch())
  } catch (e) {
    const error = e as any
    if (error === UnauthorizedError) {
      // throw so it redirects the user to the login page
      throw error
    }
    yield put(actions.setError({error: error.message}))
  }
  yield put(actions.setLoading({loading: false}))
}

export const saga = function*() {
  yield takeEvery(actions.delete, deleteSaga)
  yield takeEvery(actions.update, updateSaga)
  yield takeEvery(actions.get, getSaga)
  yield takeEvery(actions.add, addSaga)
}
